import React, { useState, useEffect } from 'react';
import '@rainbow-me/rainbowkit/styles.css';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { WagmiProvider, http } from 'wagmi';
import { watchAccount, disconnect } from '@wagmi/core'
import { RainbowKitProvider, ConnectButton, getDefaultConfig, createAuthenticationAdapter, RainbowKitAuthenticationProvider } from '@rainbow-me/rainbowkit';
import { mainnet } from 'wagmi/chains';
import { SiweMessage } from 'siwe';

export const config = getDefaultConfig({
  appName: 'SocialFi',
  projectId: '84921919730880ae393d9e04453b7ff5',
  chains: [mainnet],
  transports: {
    [mainnet.id]: http('https://eth-mainnet.g.alchemy.com/v2/dCR1zibRT2KajygxQ30CSVDst91r3imd')
  },
});

const authenticationAdapter = createAuthenticationAdapter({
  getNonce: async () => {
    const response = await fetch('/nonce');
    return await response.text();
  },

  createMessage: ({ nonce, address, chainId }) => {
    return new SiweMessage({
      domain: window.location.host,
      address,
      statement: 'Welcome to Plooshy SocialFi! Click on Sign-In to log in. This request does not trigger a blockchain transaction and does not cost any gas fees. It is only used for authentication purposes.',
      uri: window.location.origin,
      version: '1',
      chainId,
      nonce,
    });
  },

  getMessageBody: ({ message }) => {
    return message.prepareMessage();
  },

  signOut: async () => {
    return fetch("/sign-out", {
      method: "POST",
      credentials: "include",
      headers: {
        "X-CSRF-Token": getCSRF(),
      },
    }).then(async () => {
      console.log("disconnected from server, refreshing page");
      window.location.replace("/");
    });
  },

  verify: async ({ message, signature }) => {
    const verifyRes = await fetch('/sign-in', {
      method: 'POST',
      credentials: "include",
      headers: { "X-CSRF-Token": getCSRF(), 'Content-Type': 'application/json' },
      body: JSON.stringify({ message, signature }),
    });
    console.log("verifyRes", verifyRes)
    if(verifyRes.ok) {
      console.log("connected to server, refreshing page");
      window.location.replace("/profile");
    }
    return Boolean(verifyRes.ok);
  }
});

async function disconnectAccount() {
  console.log("disconnectAccount")
  await disconnect(config);
  return fetch("/sign-out", {
    method: "POST",
    credentials: "include",
    headers: {
      "X-CSRF-Token": getCSRF(),
    },
  }).then(async () => {
    console.log("disconnected from server, refreshing page");
    window.location.replace("/");
  });
}

const queryClient = new QueryClient();

const getCSRF = () => {
  return document.querySelector('meta[name="csrf-token"]').content;
};

const RainbowKit = () => {
  const [isConnectedToServer, setIsConnectedToServer] = useState(false);
  const [authStatus, setAuthStatus] = useState('loading');

  useEffect(() => {
    fetch("/me", {
      credentials: "include",
      headers: {
        "X-CSRF-Token": getCSRF(),
      },
    }).then(async (res) => {
      if (res.status === 200) {
        res.json().then(({ address }) => {
          console.log("connected to server", address);
          setIsConnectedToServer(true);
          setAuthStatus("authenticated");
        });
      } else {
        console.log("not connected to server");
        setIsConnectedToServer(false);
        setAuthStatus("unauthenticated");
      }
    }, []);

    watchAccount(config, {
      async onChange(data) {
        if (data.status == "connected") {
          console.log('connected to client', data);
        }
      },
    });
  });

  return (
    <React.Fragment>
      <React.StrictMode>
        <WagmiProvider config={config}>
          <QueryClientProvider client={queryClient}>
            <RainbowKitAuthenticationProvider
              adapter={authenticationAdapter}
              status={authStatus}
            >
              <RainbowKitProvider>
              <ConnectButton.Custom>
                {({
                  account,
                  chain,
                  openConnectModal,
                  authenticationStatus,
                  mounted,
                }) => {
                  // Note: If your app doesn't use authentication, you
                  // can remove all 'authenticationStatus' checks
                  const ready = mounted && authenticationStatus !== 'loading';
                  const connected =
                    ready &&
                    account &&
                    chain &&
                    (!authenticationStatus ||
                      authenticationStatus === 'authenticated');

                  return (
                    <div
                      {...(!ready && {
                        'aria-hidden': true,
                        'style': {
                          opacity: 0,
                          pointerEvents: 'none',
                          userSelect: 'none',
                        },
                      })}
                    >
                      {(() => {
                        if (!connected) {
                          return (
                            <button id="connect-button" onClick={openConnectModal} className="connect-button" type="button">
                              Connect
                            </button>
                          );
                        }

                        return (
                          <button onClick={disconnectAccount} className="connect-button" type="button">
                            Disconnect
                          </button>
                        );
                      })()}
                    </div>
                  );
                }}
              </ConnectButton.Custom>
              </RainbowKitProvider>
            </RainbowKitAuthenticationProvider>
          </QueryClientProvider>
        </WagmiProvider>
      </React.StrictMode>
    </React.Fragment>
  );
};

export default RainbowKit;